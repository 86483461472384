<template>
  <div class="expired_wrapper">
    <div class="text_wrapper">
      <p>
        Для обеспечения валидности тестирования вам необходимо пройти его в течение суток.
      </p>
      <p>
        Все предыдущие ответы были удалены.
      </p>
    </div>
    <div>
      <button class="btn btn-accent" @click="resumeExpiredPassing">Пройти тестирование заново</button>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "TestExpired",
  setup(props, context) {
    const store = useStore();
    const passing = computed(() => store.state.test.currentPassing);
    const resumeExpiredPassing = () => {
      store.dispatch('test/resumeExpiredPassing', passing.value.code);
      context.emit("resumeExpired");
    }

    return {
      resumeExpiredPassing,
    }
  }
}
</script>

<style scoped lang="scss">
.expired_wrapper {
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  row-gap: 50px;
}

.text_wrapper {
  font-size: 18px;
}

@media (max-width: 768px) {
  .text_wrapper {
    font-size: 16px;
  }
}
</style>