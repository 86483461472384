<template>
  <div
    v-if="passing && !isPreloader"
    class="testing-container flex-column view-container"
  >
    <test-expired
      v-if="currentPageIndex === -2"
      @resumeExpired="() => {
        isPreloader = true;
      }"
    />
    <instruction
      :requestSurvey="requestSurvey"
      @clickOnBtn="() => animate(false)"
      @skipToQuestions="skipTestForm"
      v-if="currentPageIndex === -1"
    />
    <!-- Форма-опросник -->
    <test-form
      @backToInstruction="() => animate(true)"
      @clickOnBtn="() => animate(false)"
      v-if="currentPageIndex === 0"
    />
    <question-template
      :questionsCount="pagesCount"
      :currentQuestion="currentPageIndex"
      :questionGroups="test.questionGroups"
      :isRequestPending="isRequestPending"
      :missedGroup="missedGroup"
      @prevPage="checkBeforePrevPage"
      @nextPage="sendAnswers"
      v-if="currentPageIndex > 0 && currentPageIndex <= pagesCount"
    />
    <final-page v-if="currentPageIndex === pagesCount + 1" />
  </div>
  <div
    v-else
    class="testing-container align-item-center justify-content-center"
  >
    <preloader />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Instruction from "../../feature/testing/pages/Instruction.vue";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import { useStore } from "vuex";
import QuestionTemplate from "../../feature/testing/pages/QuestionTemplate.vue";
import FinalPage from "../../feature/testing/pages/FinalPage.vue";
import TestForm from "../../feature/testing/pages/TestForm.vue";
import { useRoute, useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import Preloader from "../../components/Technical/Preloader.vue";
import TestExpired from "@/feature/testing/pages/TestExpired.vue";
export default {
  components: {TestExpired, Instruction, QuestionTemplate, FinalPage, TestForm, Preloader },
  name: "testing-page",
  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter();
    let currentPageIndex = ref(-1),
      isPreloader = ref(true);
    let pagesCount = computed(() => test.value?.questionGroups.length);
    const test = computed(() => store.state.test.test),
      passing = computed(() => store.state.test.currentPassing);

    const missedGroup = computed(() => store.state.test.missedGroup);
    const isRequestPending = ref(false);

    const sendAnswers = (answers) => {
      isRequestPending.value = true;
      store
        .dispatch("test/testStep", { code: route.params.code, answers })
        .then(() => {
            if (missedGroup.value) {
              let index = test.value.questionGroups.findIndex((el) => el.id === missedGroup.value);
              animate(true, index + 1);
            } else {
              animate(false);
            }
          isRequestPending.value = false;
        })
        .catch((error) => {
          isRequestPending.value = false;
          router.push("/test/passing");
          notify({
            type: "error",
            title: error.response.data ? error.response.data.message : error,
          });
        });
    };

    // Добавление классов-анимаций, isBack - обратное ли направление анимации (false если вперед)
    const animate = (isBack, index) => {
      let className = !isBack ? "test-animate" : "test-animate-back";
      document.querySelector(".testing-container").classList.add(className);

      setTimeout(() => {
        if (index) {
          currentPageIndex.value = index;
        } else {
          !isBack ? currentPageIndex.value++ : currentPageIndex.value--;
        }
        document
          .querySelector(".testing-container")
          .classList.remove(className);
      }, 200);
    };

    const skipTestForm = () => {
      currentPageIndex.value = 0;
      animate(false);
    }

    const checkBeforePrevPage = () => {

      if (currentPageIndex.value === 1) {
        currentPageIndex.value--
        animate(true)
      } else {
        animate(true)
      }
    }

    const requestSurvey = ref(true);
    const currentCompany = computed(() => store.state.company.userCompany);
    const profile = computed(() => store.state.profile.profile);

    onBeforeMount(() => {
      store.dispatch("profile/getProfile").then(() => {
        if (profile.value.company && profile.value.role !== 'private_person') {
          store.dispatch("company/getUserCompany").then(() => {
            requestSurvey.value = currentCompany.value.request_survey;
          });
        }
      });

      if (route.params.code) {
        store
          .dispatch("test/startTest", route.params.code)
          .then(() => {
            // Определение страницы на которой остановился пользователь
            switch (passing.value.status) {
              case "expired":
                currentPageIndex.value = -2;
                break;
              case "finished_with_errors":
              case "new":
              case "payed":
              case "instruction":
                currentPageIndex.value = -1;
                break;
              case "questionnaire":
                currentPageIndex.value = 0;
                break;
              case "in_progress":
                currentPageIndex.value =
                  test.value.questionGroups.findIndex(
                    (group) => group.id === passing.value.actual_group_id
                  ) + 1;
                break;
              case "finished":
                currentPageIndex.value = pagesCount.value + 1;
                break;
            }
            isPreloader.value = false;
          })
          .catch((error) => {
            router.push("/test/passing");
            notify({
              type: "error",
              title: error,
            });
          });
      } else router.push("/");
    });

    watch(() => passing.value, () => {
      if (passing.value && passing.value.status === 'instruction') {
        currentPageIndex.value = -1;
      }

      if (isPreloader) {
        isPreloader.value = false;
      }
    });

    return {
      requestSurvey,
      skipTestForm,
      checkBeforePrevPage,
      currentPageIndex,
      test,
      pagesCount,
      sendAnswers,
      passing,
      animate,
      isPreloader,
      isRequestPending,
      missedGroup,
    };
  },
};
</script>

<style scoped lang="scss">
.testing-container {
  min-height: 92%;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
}

.test-animate {
  animation: slide-out-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.test-animate-back {
  animation: slide-in-left 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  65% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  65% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}
</style>
