<template>
  <div class="instruction-page flex-1">
    <div class="instruction-page__content">
      <h1>Уважаемый участник тестирования!</h1>
      <div v-if="testInstruction" class="instruction-page__text">
        <p>
          {{ text }}
        </p>
      </div>
      <div v-else class="instruction-page__text">
        <p>
          Перед вами тест оценки уровня развития эмоционального интеллекта,
          разработанный под руководством д. психол. н., проф ИП РАН Е.А.
          Сергиенко на основе теоретической модели эмоционального интеллекта как
          способности. В основе теста заложено
          представление об эмоциональном интеллекте как когнитивной способности.
        </p>
        <p>
          После обработки теста Вы получите Ваш персональный отчет с
          индивидуальным профилем развития эмоционального интеллекта (ЭИ) по
          каждой из следующих шкал:
        </p>
        <ul>
          <li>идентификация эмоций;</li>
          <li>использование эмоций в решении задач;</li>
          <li>понимание и анализ эмоций;</li>
          <li>сознательное управление эмоциями.</li>
        </ul>
        <h2>Важно:</h2>
        <ol>
          <li>
            Заполнять тест самостоятельно, ни с кем не совещаясь, не прерываясь
            и не отвлекаясь. Время заполнения теста примерно 40 мин.
          </li>
          <li>
            Прочитать внимательно вопросы и по каждому пункту выбирать один
            ответ.
          </li>
          <li>
            Отвечать последовательно на каждую секцию вопросов, не перескакивать
            с секции на секцию.
          </li>
          <li>Не размышлять долго над вопросами.</li>
        </ol>
      </div>
      <div class="instruction-page__btn flex justify-content-end">
        <button @click="clickOnBtn" class="btn btn-accent">
          Начать
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import standartError from "@/mixins/standartError";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {reactive} from "@vue/reactivity";
import {computed, onBeforeMount, ref} from "@vue/runtime-core";


export default {
  name: "first-instruction",
  props: {
    title: {
      type: String,
      default: "Еще чуть-чуть и начнем!",
    },
    text: {
      type: String,
      default: "",
    },
    testInstruction: {
      type: Boolean,
      default: false,
    },
    requestSurvey: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {

    const store = useStore(),
        route = useRoute(),
        router = useRouter();

    const form = reactive({
      last_name: null,
      first_name: null,
      patronymic: null,
      age: null,
      sex: null,
      profession_id: null,
      industry_id: null,
      position_id: null,
      city: null,
      city_id: null,
    });

    const clickOnBtn = (event) => {
      // logic
      if (props.requestSurvey) {
        context.emit("clickOnBtn");
      } else {
        event.preventDefault();
        store.dispatch("test/sendTestForm", { code: route.params.code, form }).then(() => {
          context.emit("skipToQuestions");
        });
      }
    };

    const backClick = () => {
      context.emit("backClick");
    };

    return {
      form,
      clickOnBtn,
      backClick,
    };
  },
};
</script>

<style scoped lang="scss">
.instruction-page {
  text-align: left;
  padding: 20px 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;

  &__content {
    padding: 20px 40px;

    h1 {
      font-size: 45px;
    }
    p {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }

  &__text {
    margin-bottom: 50px;
    counter-reset: list;

    h2 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 1em;
      letter-spacing: 0.01em;
    }

    ul li,
    ol li {
      font-size: 17px;
      position: relative;
      padding-left: 28px;
      margin-bottom: 5px;
      line-height: 1.3em;
    }
    ol {
      list-style: none;
    }

    ul li::before {
      position: absolute;
      content: "—";
      left: 0px;
    }

    ol li::before {
      position: absolute;
      counter-increment: list;
      content: counter(list) ".";
      left: 0;
    }
  }

  &__btn {
    button {
      margin-right: 10px;
    }
  }

  .btn {
    font-size: 20px;
  }

  @media (max-width: 1036px) {
    &__content {
      padding: 0 20px 20px 20px;
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 16px;
      }
      h2 {
        font-size: 22px;
      }
      ul li,
      ol li {
        font-size: 15px;
      }
    }
  }
}
</style>
