<template>
  <div
    v-if="questionGroup"
    class="
      flex flex-column
      justify-content-center
      tabs-question
      align-item-center
      w-full
    "
  >
    <img
      v-if="questionGroup.image"
      class="tabs-question__image"
      :src="`${imageUrl}/${questionGroup.image}`"
      alt="Картинка"
    />
    <p class="tabs-question__description">{{ questionGroup.text }}</p>
    <div v-if="questionGroup.questions" class="w-full">
      <div
        v-for="question in questionGroup.questions"
        :key="question.id"
        class="tabs-question__answer"
      >
        <p v-if="question.text" class="tabs-question__text">
          {{ question.text }}
        </p>
        <div
          class="
            tabs-question__btns
            flex
            justify-content-center
            align-item-center
          "
        >
          <div
            v-for="(answer, index) in question.answers"
            :key="answer.id"
            class="flex flex-1 justify-content-center"
          >
            <custom-radio
              :id="`answer_${question.id}_${answer.id}`"
              :name="`answer_${question.id}`"
              :type="question.answers_num < 2 ? 'radio' : 'checkbox'"
              @change="setAnswer"
              :data-answer="answer.id"
              :value="answer.value"
              :label="answer.text ? answer.text : text[index]"
              :data-question="question.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import { watch } from "@vue/runtime-core";
import useAnswers from "@/mixins/useAnswers";
export default {
  components: { CustomRadio },
  name: "tabs-question-group",
  props: {
    questionGroup: {
      type: Object,
    },
    missedGroup: {
      type: Number,
      default: 0,
    }
  },
  inject: ["imageUrl"],
  setup(props, context) {
    const group = toRef(props, "questionGroup");
    const missedGroup = toRef(props, "missedGroup");
    const { setAnswer, clearAnswers } = useAnswers(context);
    const text = [
      "Очень неэффективно",
      "Достаточно эффективно",
      "Нейтрально",
      "Достаточно эффективно",
      "Очень эффективно",
    ];

    watch(group, () => {
      clearAnswers();
    });

    watch(missedGroup, () => {
      if (missedGroup.value === group.value.id) {
        clearAnswers();
      }
    });

    return {
      setAnswer,
      text,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabs-question {
  white-space: nowrap;
  &__image {
    display: block;
    max-width: 600px;
    max-height: 300px;
    margin-bottom: 15px;
    border-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }

  &__answer {
    border: 1px solid #ececee;
    border-radius: 4px;
    padding: 25px 75px;
    margin-top: 20px;
    width: 100%;
  }

  &__description {
    font-size: 20px;
    word-break: break-word;
    max-width: 100%;
    white-space: initial;
  }

  &__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 15px;
    white-space: break-spaces;
    word-break: break-word;
  }

  &__btns {
    flex-wrap: wrap;
  }

  @media (max-width: 1100px) {
    &__btns > div {
      margin: 0 5px;
    }
  }

  @media (max-width: 1230px) {
    &__answer {
      padding: 20px 20px;
    }
    &__btns {
      flex-direction: column;
      align-items: flex-start;

      & > div {
        margin: 5px 0;
      }
    }

    &__description {
      font-size: 17px;
    }

    &__image {
      max-width: 90%;
    }
  }
}
</style>